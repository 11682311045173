import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

function DeleteTech() {
    const [gameList, setGameList] = useState([]);
    const { login } = useContext(LoginContext);

  useEffect(() => {
    Axios.get("https://server.friendlyapk.com/api/gettech").then((res) => {
      setGameList(res.data);
    });
  }, []);

  const DeleteGame = (gameId) => {
    Axios.delete(`https://server.friendlyapk.com/api/deletetech/${gameId}`);
  };
   if (login) {
    return (
      <div className="DeleteForm">
        <Header />
        {gameList.map((value) => {
          return (
            <div className="deletecard" key={value.id.toString()}>
              <h1>{value.name} </h1>
              <button
                onClick={() => {
                  DeleteGame(value.id);
                }}
              >
                Delete
              </button>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <Navigate to="../" />;
  }
}

export default DeleteTech