//  IMPORT COMPONENTS
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// Import PAGES
import "./App.css";
import NewTech from "./NewTech";
import EditTech from "./editTech";
import InsertForm from "./newGameInsert";
import DeleteForm from "./DeleteGame";
import EditGame from "./EditGame";
import DeleteTech from "./DeleteTech";
import Admin from "./admin";
// IMPORT PARTS
import ScrollToTop from "./ScrollToTop";
import { LoginContext } from "./Context";
import gamepadlogo from "./img/gamepad.png"
function App() {
  const [login, setLogin] = useState(false);

  return (
    <LoginContext.Provider value={{ login, setLogin }} className="App">
      <ScrollToTop />
        <div className="logo">
          <img src={gamepadlogo} alt="Logo" />
          <h1>FriendlyAPK</h1>
        </div>
     
      <Routes>
      <Route path="/" element={<Admin />} />
        <Route path="/newtech" element={<NewTech />} />
        <Route path="/edittech" element={<EditTech />} />
        <Route path="/insertform" element={<InsertForm />} />
        <Route path="/deleteform" element={<DeleteForm />} />
        <Route path="/editform" element={<EditGame />} />
        <Route path="/deletetech" element={<DeleteTech />} />
      </Routes>
    </LoginContext.Provider>
  );
}

export default App;
