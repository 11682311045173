import React from "react";
import "./App.css";

import Header from "./Header";
import Sign from "./Sign";

function Admin() {
  return (
      <main className="admincontent">
        <Header />
        <Sign />
      </main>
  );
}
export default Admin;
