import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

function NewTech() {
  const [techName, setTechName] = useState();
  const [techCompany, setTechCompany] = useState();
  const [techSupport, setTechSupport] = useState();
  const [techDevice, setTechDevice] = useState();
  const [techImg, setTechImg] = useState();
  const [techAmzLink, setTechAmzLink] = useState();
  const [techDescription, setTechDescription] = useState();
  const token = localStorage.getItem("token");
  const { login } = useContext(LoginContext);
  const handleTech = (e) => {
    Axios.post(
      "https://server.friendlyapk.com/api/newtech",{
        token:token,
        techName: techName,
        techCompany: techCompany,
        techSupport: techSupport,
        techDevice: techDevice,
        techImg: techImg,
        techAmzLink: techAmzLink,
        techDescription: techDescription,
      }
    ).then(() => {
      alert("Successfully Inserted");
    });
    e.preventDefault();
    e.target.reset();
  };
  if (login) {
    return (
      <main>
        <Header />
        <h2 className="h2">Add Gadget</h2>
        <form action="../newtech" onSubmit={handleTech} className="InsertFormGame">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id=""
            onChange={(e) => {
              setTechName(e.target.value);
            }}
          />

          <label htmlFor="company">Company</label>
          <input
            type="text"
            name="company"
            id=""
            onChange={(e) => {
              setTechCompany(e.target.value);
            }}
          />

          <label htmlFor="support">Support</label>
          <input
            type="text"
            name="support"
            id=""
            onChange={(e) => {
              setTechSupport(e.target.value);
            }}
          />

          <label htmlFor="category1">Device Type</label>
          <select
            name="category1"
            onChange={(e) => {
              setTechDevice(e.target.value);
            }}
          >
            <option value="">Please Select Type</option>

            <option value="controller">Controller</option>

            <option value="tablet">Tablet</option>

            <option value="vr">Virtual Reality</option>

            <option value="accessories">Accessories</option>
          </select>

          <label htmlFor="img">Image</label>
          <input
            type="url"
            name="img"
            id=""
            onChange={(e) => {
              setTechImg(e.target.value);
            }}
          />

          <label htmlFor="amzlink">Affliate Link</label>
          <input
            type="url"
            name="amzlink"
            id=""
            onChange={(e) => {
              setTechAmzLink(e.target.value);
            }}
          />

          <label htmlFor="description">Description</label>
          <input
            type="text"
            name="description"
            rows="8"
            onChange={(e) => {
              setTechDescription(e.target.value);
            }}
          />
          <button type="submit">Submit</button>
        </form>
      </main>
    );
  } else {
    return <Navigate to="../" />;
  }
}
export default NewTech;
