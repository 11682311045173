import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

function InsertForm() {
  const [gameName, setGameName] = useState("");
  const [gameCost, setGameCost] = useState("");
  const [gameAndroid, setGameAndroid] = useState("");
  const [gameController, setGameController] = useState("");
  const [gameControllerName, setGameControllerName] = useState("");
  const [gameVR, setGameVR] = useState("");
  const [gameVRName, setGameVRName] = useState("");
  const [gameTablet, setGameTablet] = useState("");
  const [gameTabletName, setGameTabletName] = useState("");
  const [gameAge, setGameAge] = useState("");
  const [gameCategory1, setGameCategory1] = useState("");
  const [gameCategory2, setGameCategory2] = useState("");
  const [gameCategory3, setGameCategory3] = useState("");
  const [gameCategory1Name, setGameCategory1Name] = useState("");
  const [gameCategory2Name, setGameCategory2Name] = useState("");
  const [gameCategory3Name, setGameCategory3Name] = useState("");
  const [gameScore, setGameScore] = useState("");
  const [gameDeveloper, setGameDeveloper] = useState("");
  const [gamePublisher, setGamePublisher] = useState("");
  const [gameLinkPlay, setGameLinkPlay] = useState("");
  const [gameLinkApple, setGameLinkApple] = useState("");
  const [gameVLink, setGameVLink] = useState("");
  const [gameIconName, setGameIconName] = useState("");
  const [gameImageName, setGameImageName] = useState("");
  const [gameDiscription, setGameDiscription] = useState("");
  const token = localStorage.getItem("token");
  const { login } = useContext(LoginContext);


  const submitGame = () => {
    Axios.post("https://server.friendlyapk.com/api/insertgame", {
      gameName: gameName,
      gameCost: gameCost,
      gameAndroid: gameAndroid,
      gameController: gameController,
      gameVR: gameVR,
      gameTablet: gameTablet,
      gameControllerName: gameControllerName,
      gameVRName: gameVRName,
      gameTabletName: gameTabletName,
      gameAge: gameAge,
      gameCategory1: gameCategory1,
      gameCategory2: gameCategory2,
      gameCategory3: gameCategory3,
      gameCategory1Name: gameCategory1Name,
      gameCategory2Name: gameCategory2Name,
      gameCategory3Name: gameCategory3Name,
      gameScore: gameScore,
      gameDeveloper: gameDeveloper,
      gamePublisher: gamePublisher,
      gameLinkPlay: gameLinkPlay,
      gameLinkApple: gameLinkApple,
      gameVLink: gameVLink,
      gameIconName: gameIconName,
      gameImageName: gameImageName,
      gameDiscription: gameDiscription,
      token:token
    }).then(() => {
      alert("Successfully Inserted");
    });
  };
  if(login){
return (
    <div className="InsertFormGame">
      <Header />
      <h2>Add Games</h2>

      <label htmlFor="name">Name</label>
      <input
        type="text"
        name="name"
        id=""
        onChange={(e) => {
          setGameName(e.target.value);
        }}
      />

      <label htmlFor="cost">Cost</label>
      <input
        type="text"
        name="cost"
        id=""
        onChange={(e) => {
          setGameCost(e.target.value);
        }}
      />

      <label htmlFor="android">Android</label>
      <input
        type="text"
        name="android"
        id=""
        onChange={(e) => {
          setGameAndroid(e.target.value);
        }}
      />

      <label htmlFor="controller">Controller Support</label>
      <fieldset>
        <input
          type="radio"
          name="controller"
          id=""
          value="https://i.ibb.co/XsMPZCv/console.png"
          onChange={(e) => {
            setGameController(e.target.value);
            setGameControllerName("Supported By Controller");
          }}
        />
        Yes
        <input
          type="radio"
          name="controller"
          id=""
          value=""
          onChange={(e) => {
            setGameController(e.target.value);
            setGameControllerName("");
          }}
        />
        No
      </fieldset>

      <label htmlFor="VR">VR Support</label>
      <fieldset>
        <input
          type="radio"
          name="VR"
          id=""
          value="https://i.ibb.co/CsrpHYT/vr.png"
          onChange={(e) => {
            setGameVR(e.target.value);
            setGameVRName("VR Game Check");
          }}
        />
        Yes
        <input
          type="radio"
          name="VR"
          id=""
          value=""
          onChange={(e) => {
            setGameVR(e.target.value);
            setGameVRName("");
          }}
        />
        No
      </fieldset>

      <label htmlFor="tablet">Tablet Compatibility</label>
      <fieldset>
        <input
          type="radio"
          name="tablet"
          id=""
          value="https://i.ibb.co/Wfz78Xw/tablet.png"
          onChange={(e) => {
            setGameTablet(e.target.value);
            setGameTabletName("Perfect For Tablet");
          }}
        />
        Yes
        <input
          type="radio"
          name="tablet"
          id=""
          value=""
          onChange={(e) => {
            setGameTablet(e.target.value);
            setGameTabletName("");
          }}
        />
        No
      </fieldset>

      <label htmlFor="age">Age Rating</label>
      <select
        name="age"
        id=""
        required
        onChange={(e) => {
          setGameAge(e.target.value);
        }}
      >
        <option onClick={(e) => {
          
        }} value="">Please Select</option>
        <option onClick={(e) => {
          
        }} value="https://i.ibb.co/dG41ZWS/E.png">Everyone</option>
        <option onClick={(e) => {
          
        }} value=" https://i.ibb.co/HV9RsHm/E10.png">Everyone 10 +</option>
        <option onClick={(e) => {
          
        }} value=" https://i.ibb.co/Jm0z0zp/T.png">Teen</option>
        <option onClick={(e) => {
          
        }} value="https://i.ibb.co/Z1bCvV2/M.png">Mature</option>
        <option onClick={(e) => {
          
        }} value=" https://i.ibb.co/bJ76DK5/A.png">Adult Only</option>
      </select>

      <label htmlFor="category1">Category 1</label>
      <select
        name="category1"
        onChange={(e) => {
          setGameCategory1(e.target.value);
        }}
      >
        <option
          onClick={(e) => {
            setGameCategory1Name("");
          }}
          value=""
        >
          Please Select
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Action");
          }}
          value="https://i.ibb.co/fk2y9PX/action.png"
        >
          Action
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Adventure");
          }}
          value="https://i.ibb.co/FDvGJMX/Adventure.png"
        >
          Adventure
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Arcade");
          }}
          value="https://i.ibb.co/3rY5FwF/arcade.png"
        >
          Arcade
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Board");
          }}
          value="https://i.ibb.co/JBdDvt8/board.png"
        >
          Board
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Casual");
          }}
          value="https://i.ibb.co/mDpBnVR/casual.png"
        >
          Casual
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Education");
          }}
          value="https://i.ibb.co/NWwSnc7/education.png"
        >
          Education
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Music");
          }}
          value="https://i.ibb.co/9ZD0DJ3/music.png"
        >
          Music
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Racing");
          }}
          value="https://i.ibb.co/NLn8Ryt/race.png"
        >
          Racing
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Simulation");
          }}
          value="https://i.ibb.co/wpPCk03/simulation.png"
        >
          Simulation
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Sport");
          }}
          value="https://i.ibb.co/whP29wP/Sport.png"
        >
          Sport
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Strategy");
          }}
          value="https://i.ibb.co/7XGrnZn/strategy.png"
        >
          Strategy
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Shooting");
          }}
          value="https://i.ibb.co/4tD59X4/shooting.png"
        >
          Shooting
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Solo Player");
          }}
          value="https://i.ibb.co/TT6xBrm/spy.png"
        >
          Solo Player
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Multi Player");
          }}
          value="https://i.ibb.co/xDF0gH8/role-playing-game.png"
        >
          Multi Player
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Role Playing");
          }}
          value="https://i.ibb.co/xY7xmrC/swordsman.png"
        >
          Role Playing
        </option>
      </select>
      


      <label htmlFor="category2">Category 2</label>
      <select
        name="category2"
        onChange={(e) => {
          setGameCategory2(e.target.value);
          setGameCategory2Name(e.target.dataset.category2name);
        }}
      >
        <option value="">Please Select</option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Action");
          }}
          value="https://i.ibb.co/fk2y9PX/action.png"
        >
          Action
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Adventure");
          }}
          value="https://i.ibb.co/FDvGJMX/Adventure.png"
        >
          Adventure
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Arcade");
          }}
          value="https://i.ibb.co/3rY5FwF/arcade.png"
        >
          Arcade
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Board");
          }}
          value="https://i.ibb.co/JBdDvt8/board.png"
        >
          Board
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Casual");
          }}
          value="https://i.ibb.co/mDpBnVR/casual.png"
        >
          Casual
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Education");
          }}
          value="https://i.ibb.co/NWwSnc7/education.png"
        >
          Education
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Music");
          }}
          value="https://i.ibb.co/9ZD0DJ3/music.png"
        >
          Music
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Racing");
          }}
          value="https://i.ibb.co/NLn8Ryt/race.png"
        >
          Racing
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Simulation");
          }}
          value="https://i.ibb.co/wpPCk03/simulation.png"
        >
          Simulation
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Sport");
          }}
          value="https://i.ibb.co/whP29wP/Sport.png"
        >
          Sport
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Strategy");
          }}
          value="https://i.ibb.co/7XGrnZn/strategy.png"
        >
          Strategy
        </option>
        <option
          onClick={(e) => {
            setGameCategory2Name("Shooting");
          }}
          value="https://i.ibb.co/4tD59X4/shooting.png"
        >
          Shooting
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Solo Player");
          }}
          value="https://i.ibb.co/TT6xBrm/spy.png"
        >
          Solo Player
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Multi Player");
          }}
          value="https://i.ibb.co/xDF0gH8/role-playing-game.png"
        >
          Multi Player
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Role Playing");
          }}
          value="https://i.ibb.co/xY7xmrC/swordsman.png"
        >
          Role Playing
        </option>
      </select>

      <label htmlFor="category3">Category 3</label>
      <select
        name="category3"
        onChange={(e) => {
          setGameCategory3(e.target.value);
          setGameCategory3Name(e.target.dataset.category3name);
        }}
      >
        <option value="">Please Select</option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Action");
          }}
          value="https://i.ibb.co/fk2y9PX/action.png"
        >
          Action
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Adventure");
          }}
          value="https://i.ibb.co/FDvGJMX/Adventure.png"
        >
          Adventure
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Arcade");
          }}
          value="https://i.ibb.co/3rY5FwF/arcade.png"
        >
          Arcade
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Board");
          }}
          value="https://i.ibb.co/JBdDvt8/board.png"
        >
          Board
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Casual");
          }}
          value="https://i.ibb.co/mDpBnVR/casual.png"
        >
          Casual
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Education");
          }}
          value="https://i.ibb.co/NWwSnc7/education.png"
        >
          Education
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Music");
          }}
          value="https://i.ibb.co/9ZD0DJ3/music.png"
        >
          Music
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Racing");
          }}
          value="https://i.ibb.co/NLn8Ryt/race.png"
        >
          Racing
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Simulation");
          }}
          value="https://i.ibb.co/wpPCk03/simulation.png"
        >
          Simulation
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Sport");
          }}
          value="https://i.ibb.co/whP29wP/Sport.png"
        >
          Sport
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Strategy");
          }}
          value="https://i.ibb.co/7XGrnZn/strategy.png"
        >
          Strategy
        </option>
        <option
          onClick={(e) => {
            setGameCategory3Name("Shooting");
          }}
          value="https://i.ibb.co/4tD59X4/shooting.png"
        >
          Shooting
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Solo Player");
          }}
          value="https://i.ibb.co/TT6xBrm/spy.png"
        >
          Solo Player
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Multi Player");
          }}
          value="https://i.ibb.co/xDF0gH8/role-playing-game.png"
        >
          Multi Player
        </option>
        <option
          onClick={(e) => {
            setGameCategory1Name("Role Playing");
          }}
          value="https://i.ibb.co/xY7xmrC/swordsman.png"
        >
          Role Playing
        </option>
      </select>

      <label htmlFor="score">APK Friendly Score</label>
      <input
        type="text"
        name="score"
        id=""
        onChange={(e) => {
          setGameScore(e.target.value);
        }}
      />

      <label htmlFor="developer">Developer</label>
      <input
        type="text"
        name="developer"
        id=""
        onChange={(e) => {
          setGameDeveloper(e.target.value);
        }}
      />

      <label htmlFor="publisher">Publisher</label>
      <input
        type="text"
        name="publisher"
        id=""
        onChange={(e) => {
          setGamePublisher(e.target.value);
        }}
      />

      <label htmlFor="linkplay">PlayStore URL</label>
      <input
        type="url"
        name="linkplay"
        id=""
        onChange={(e) => {
          setGameLinkPlay(e.target.value);
        }}
      />

      <label htmlFor="applelink">Apple Store URL</label>
      <input
        type="url"
        name="applelink"
        id=""
        onChange={(e) => {
          setGameLinkApple(e.target.value);
        }}
      />

      <label htmlFor="vlink">URL of Youtube video</label>
      <input
        type="url"
        name="vlink"
        id=""
        onChange={(e) => {
          setGameVLink(e.target.value);
        }}
      />

      <label htmlFor="icon">Upload Game Icon</label>
      <input
        type="url"
        name="icon"
        id=""
        accept=".ico,.svg,.gif"
        onChange={(e) => {
          setGameIconName(e.target.value);
        }}
      />

      <label htmlFor="image">Upload Game Image</label>
      <input
        type="url"
        name="image"
        id=""
        accept=".gif,.jpg,.jpeg,.png"
        onChange={(e) => {
          setGameImageName(e.target.value);
        }}
      />

      <label htmlFor="discription">Discription</label>
      <input
        type="text"
        name="discription"
        id=""
        onChange={(e) => {
          setGameDiscription(e.target.value);
        }}
      />

      <button type="submit" onClick={submitGame}>
        
        Submit
      </button>
    </div>
  );
  }else{
    return <Navigate to="../" />;
  }
  
}

export default InsertForm;
