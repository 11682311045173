import React from "react";
import "./App.css";
import { Link } from "react-router-dom";

function Header() {
    return(
        <header className="adminaside">
        <Link to="../"> <img src="https://i.ibb.co/brQQ9qP/sign.png" alt="Sign in out" /> </Link>
       <Link to="../insertform" ><img src="https://i.ibb.co/DRsspvv/plus.png" alt="Add Game" /></Link>
       <Link to="../editform" ><img src="https://i.ibb.co/282RVxT/edit.png" alt="Edit Game" /></Link>
       <Link to="../deleteform" ><img src="https://i.ibb.co/WvBmytm/trash-can.png" alt="Delete Game" /></Link>
       <Link to="../newtech" ><img src="https://i.ibb.co/sgWrymH/game-controller.png" alt="Add Gear" /></Link>
       <Link to="../edittech" ><img src="https://i.ibb.co/ccrfqLV/settings.png" alt="Edit Gear" /></Link>
       <Link to="../deletetech"> <img src="https://i.ibb.co/r7kwrV8/minus.png" alt="Delete Tech"  /> </Link>
      </header>
    )
}
export default Header;