import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "./Context";
import "./App.css";
import Axios from "axios";
import Header from "./Header";

function EditTech() {
  const [techList, setTechList] = useState([]);
  const { login } = useContext(LoginContext);
  const [techId, setTechId] = useState("");
  const [techName, setTechName] = useState("");
  const [techCompany, setTechCompany] = useState("");
  const [techSupport, setTechSupport] = useState("");
  const [techDevice, setTechDevice] = useState("");
  const [techImg, setTechImg] = useState("");
  const [techAmzLink, setTechAmzLink] = useState("");
  const [techDescription, setTechDescription] = useState("");
  const token = localStorage.getItem("token");
  useEffect(() => {
    Axios.get("https://server.friendlyapk.com/api/gettech").then((res) => {
      setTechList(res.data);
    });
  }, []);

  const handleTech = (e) => {
    Axios.put("https://server.friendlyapk.com/api/edittech", {
      techId: techId,
      techName: techName,
      techCompany: techCompany,
      techSupport: techSupport,
      techDevice: techDevice,
      techImg: techImg,
      techAmzLink: techAmzLink,
      techDescription: techDescription,
      token:token
    }).then(() => {
      alert("Successfully Inserted");
    });
    e.preventDefault();
    e.target.reset();
  };

  if(login){
return (
    <main>
      <Header />
      <h2 className="h2">Edit Tech </h2>
      <form action="../edittech" onSubmit={handleTech} className="InsertFormGame">
        <label htmlFor="id">Select Any Tech</label>
        <select
          name="id"
          onChange={(e) => {
            setTechId(e.target.value);
          }}
        >
          <option value="">Please Select</option>
          {techList.map((value) => {
              return(
                  <option value={value.id} key={value.id} 
                  onClick={() => { 
                      setTechName(value.name);
                      setTechSupport(value.support);
                      setTechCompany(value.company);
                      setTechDevice(value.device);
                      setTechAmzLink(value.amzlink);
                      setTechDescription(value.description);
                      setTechImg(value.deviceimg);
                   }}
                  >
                      {value.name}
                  </option>
              )
          })}
          
        </select>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          id=""
          placeholder={techName}
          onChange={(e) => {
            setTechName(e.target.value);
          }}
        />

        <label htmlFor="company">Company</label>
        <input
          type="text"
          name="company"
          id=""
          placeholder={techCompany}
          onChange={(e) => {
            setTechCompany(e.target.value);
          }}
        />

        <label htmlFor="support">Support</label>
        <input
          type="text"
          name="support"
          id=""
          placeholder={techSupport}
          onChange={(e) => {
            setTechSupport(e.target.value);
          }}
        />

        <label htmlFor="category1">Category 1</label>
        <select
          name="category1"
          onChange={(e) => {
            setTechDevice(e.target.value);
          }}
        >
          <option value="">Please Select Type</option>

          <option value="controller">Controller</option>

          <option value="tablet">Tablet</option>

          <option value="vr">Virtual Reality</option>

          <option value="accessories">Accessories</option>
        </select>

        <label htmlFor="img">Image</label>
        <input
          type="url"
          name="img"
          id=""
          placeholder={techImg}
          onChange={(e) => {
            setTechImg(e.target.value);
          }}
        />

        <label htmlFor="amzlink">Affliate Link</label>
        <input
          type="url"
          name="amzlink"
          id=""
          placeholder={techAmzLink}
          onChange={(e) => {
            setTechAmzLink(e.target.value);
          }}
        />

        <label htmlFor="description">Description</label>
        <input
          type="text"
          name="description"
          rows="8"
          placeholder={techDescription}
          onChange={(e) => {
            setTechDescription(e.target.value);
          }}
        />
        <button type="submit">Submit</button>
      </form>
    </main>
  );
  }else{
    return <Navigate to="../" />;
  }
  
}
export default EditTech;
